import { MatrixOptions } from '../types';

export default Object.seal({
    rainWidth: 10,
    rainHeight: 8,
    minFrameTime: 50,
    rainGenerator: Object.seal({
        count: 200,
    }),
    rainDrop: Object.seal({
        direction: "TD",
        charArrays: ["⠁⠂⠃⠄⠅⠆⠇⠈⠉⠊⠋⠌⠍⠎⠏⠐⠑⠒⠓⠔⠕⠖⠗⠘⠙⠚⠛⠜⠝⠞⠟⠠⠡⠢⠣⠤⠥⠦⠧⠨⠩⠪⠫⠬⠭⠮⠯⠰⠱⠲⠳⠴⠵⠶⠷⠸⠹⠺⠻⠼⠽⠾⠿⡀⡁⡂⡃⡄⡅⡆⡇⡈⡉⡊⡋⡌⡍⡎⡏⡐⡑⡒⡓⡔⡕⡖⡗⡘⡙⡚⡛⡜⡝⡞⡟⡠⡡⡢⡣⡤⡥⡦⡧⡨⡩⡪⡫⡬⡭⡮⡯⡰⡱⡲⡳⡴⡵⡶⡷⡸⡹⡺⡻⡼⡽⡾⡿⢀⢁⢂⢃⢄⢅⢆⢇⢈⢉⢊⢋⢌⢍⢎⢏⢐⢑⢒⢓⢔⢕⢖⢗⢘⢙⢚⢛⢜⢝⢞⢟⢠⢡⢢⢣⢤⢥⢦⢧⢨⢩⢪⢫⢬⢭⢮⢯⢰⢱⢲⢳⢴⢵⢶⢷⢸⢹⢺⢻⢼⢽⢾⢿⣀⣁⣂⣃⣄⣅⣆⣇⣈⣉⣊⣋⣌⣍⣎⣏⣐⣑⣒⣓⣔⣕⣖⣗⣘⣙⣚⣛⣜⣝⣞⣟⣠⣡⣢⣣⣤⣥⣦⣧⣨⣩⣪⣫⣬⣭⣮⣯⣰⣱⣲⣳⣴⣵⣶⣷⣸⣹⣺⣻⣼⣽⣾⣿"],
        headColor: "rgba(255,0,0,0.8)",
        trailColor: "rgba(255,110,62,1)",
        fontSize: 16,
        fontFamily: "Arial",
        randomizeFrameDelay: true,
        jitterRightStrength: 1,
        jitterLeftStrength: 0,
        jitterUpStrength: 0,
        jitterDownStrength: 0
    }),
    trailBloomSize: 12,
    trailBloomColor: "#ff0000",
    headBloomSize: 4,
    headBloomColor: "#ffe500",

    warmupIterations: 50,
    fadeStrength: 0.05,
}) as MatrixOptions;